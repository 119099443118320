import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { generateSignature, addProductGallery, removeProductGallery } from "../../redux/apiCalls";
import axios from "axios";

export default function ProductGallery() {
    const location = useLocation();
    const productId = location.pathname.split("/")[2];
    const [file, setFile] = useState(null);
    const [gallery, setGallery] = useState([]);
    const dispatch = useDispatch();

    const product = useSelector((state) =>
        state.product.products.find((product) => product._id === productId)
    );

    useEffect(() => {
        setGallery(product?.gallery || []);
    }, [product]);

    const handleAdd = async () => {
        if (file == null) {
            return alert("Image is required for creating new product");
        }

        const fileName = new Date().getTime() + file.name;
        const imageresult = await generateSignature();
        const imageFormData = new FormData();

        imageFormData.append("file", file);
        imageFormData.append(
            "publicKey",
            "public_0THMWX6MF/cuVk9EPEFy+e0R0Qk="
        );
        imageFormData.append("signature", imageresult.data.signature);
        imageFormData.append("expire", imageresult.data.expire);
        imageFormData.append("token", imageresult.data.token);
        imageFormData.append(
            "fileName",
            `${fileName} - ${imageresult.data.expire}`
        );
        imageFormData.append("folder", "haofa");

        try {
            const imageUploadResult = await axios.post(
                "https://upload.imagekit.io/api/v1/files/upload",
                imageFormData,
                { headers: { "Content-Type": "multipart/form-data" } }
            );

            const newImage = {
                img: imageUploadResult.data.url,
            };

            // Update Redux and server
            await addProductGallery(product._id, newImage, dispatch);

            // Dynamically update local gallery state
            setGallery((prevGallery) => [...prevGallery, newImage]);

        } catch (error) {
            alert("Failed to add image");
        }
    };

    const handleDelete = (index) => {
        const updatedGallery = [...gallery];
        updatedGallery.splice(index, 1);
        setGallery(updatedGallery); // Update local gallery state
        removeProductGallery(product._id, updatedGallery, dispatch);
    };

    return (
        <div className="product">
            <h1 className="addProductTitle">Add Product Gallery</h1>
            <div className="addProductForm">
                <div className="addProductItem">
                    <label>Image</label>
                    <input
                        type="file"
                        id="file"
                        onChange={(e) => setFile(e.target.files[0])}
                    />
                </div>
                <button onClick={handleAdd} className="addProductButton">
                    Add Product Gallery
                </button>
            </div>
            <div className="marginTop">
                {gallery.map((image, index) => (
                    <div key={index} className="galleryItem">
                        <img
                            alt={`product gallery ${index + 1}`}
                            className="galleryImage"
                            src={image ? image.img : ""}
                        />
                        <button
                            className="deleteButton"
                            onClick={() => handleDelete(index)}
                        >
                            X
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
}
