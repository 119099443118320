import { loginFailure, loginStart, loginSuccess } from "./userRedux";
import { publicRequest, userRequest } from "../requestMethods";
import {
    getProductFailure,
    getProductStart,
    getProductSuccess,
    getAccessoryFailure,
    getAccessoryStart,
    getAccessorySuccess,
    deleteProductFailure,
    deleteProductStart,
    deleteProductSuccess,
    updateProductFailure,
    updateProductStart,
    updateProductSuccess,
    addProductFailure,
    addProductStart,
    addProductSuccess,
    cancelOrderStart,
    voidOrderStart,
    addStockStart,
    addStockSuccess,
    addStockFailure,
    getStockFailure,
    getStockStart,
    getStockSuccess,
    updateStockFailure,
    updateStockStart,
    updateStockSuccess,
    getTransactionStart,
    getTransactionSuccess,
    getTransactionFailure,
    getProductReportStart,
    getProductReportSuccess,
    getProductReportFailure,
    updateProductLabelStart,
    updateProductLabelFailure,
} from "./productRedux";

import {
    addWarrantyFailure,
    addWarrantyStart,
    addWarrantySuccess,
    getWarrantyStart,
    getWarrantySuccess,
    getWarrantyFailure,
    deleteWarrantyFailure,
    deleteWarrantyStart,
    deleteWarrantySuccess,
} from "./warrantyRedux";

import { getUserFailure, getUserStart, getUserSuccess } from "./userRedux";

import {
    getSalesReportStart,
    getSalesReportSuccess,
    getSalesReportFailure,
    getOrderFailure,
    getOrderStart,
    getMallReportFailure,
    getMallReportStart,
    getVelocityReportSuccess,
    getToppenReportSuccess,
    getReportStart,
    getReportSuccess,
    getReportFailure,
} from "./orderRedux";

import {
    getAffiliatesFailure,
    getAffiliatesStart,
    getAffiliatesSuccess,
    addAffiliateStart,
    addAffiliateSuccess,
    addAffiliateFailure,
    deleteAffiliateStart,
    deleteAffiliateSuccess,
    deleteAffiliateFailure,
    getAffiliatesReportFailure,
    getAffiliatesReportStart,
    getAffiliatesReportSuccess,
} from "./affiliateRedux";

import {
    getCampaignsStart,
    getCampaignsSuccess,
    getCampaignsFailure,
    voidCampaignStart,
    acceptCampaignStart,
} from "./campaignRedux";
import createWarrantyReturn from "../components/pdf/createWarrantyReturn";
import createWarrantyComplete from "../components/pdf/createWarrantyComplete";
import createCampaignInvoice from "../components/pdf/createCampaignInvoice";
import createDeliveryOrderForm from "../components/pdf/createDeliveryOrderForm";

export const login = async (dispatch, user) => {
    dispatch(loginStart());
    try {
        const res = await publicRequest.post("/auth/login", user);
        dispatch(loginSuccess(res.data));
    } catch (err) {
        dispatch(loginFailure());
        alert("Login Failed, please check your password");
    }
};

export const register = async (user) => {
    try {
        const result = await publicRequest.post("/auth/register", user.user);
        if (result.data) {
            alert("Your user account has successfully been created.");
            window.location.href = "/users";
        }
    } catch (err) {
        alert("Phone Number / Email address has been taken.");
    }
};

export const registerAdmin = async (user) => {
    try {
        const payload = {
            email: user.user.email,
            password: user.user.password,
            name: user.user.name,
            username: user.user.username,
            phoneNumber: user.user.phoneNumber,
            address: {
                lineOne: user.user.lineOne,
                lineTwo: user.user.lineTwo,
                zip: user.user.zip,
                city: user.user.city,
                country: user.user.country,
            },
            type: user.user.type ? user.user.type : "consignment",
        };
        const result = await publicRequest.post("/auth/registeradmin", payload);
        if (result.data) {
            alert("Outlet account has been created.");
        }
    } catch (err) {
        alert("Username or email has been taken.");
    }
};

export const getProducts = async (dispatch, filters) => {
    dispatch(getProductStart());
    try {
        const res = await publicRequest.get("/products/cashier", {
            params: { filters },
        });
        dispatch(getProductSuccess(res.data));
    } catch (err) {
        dispatch(getProductFailure());
    }
};

export const getAccessories = async (dispatch, filters) => {
    dispatch(getAccessoryStart());
    try {
        const res = await publicRequest.get("/products/accessory", {
            params: { filters },
        });
        dispatch(getAccessorySuccess(res.data));
    } catch (err) {
        dispatch(getAccessoryFailure());
    }
};

export const deleteProduct = async (id, dispatch) => {
    dispatch(deleteProductStart());
    try {
        const result = await userRequest.put(`/products/delete/${id}`);
        if (result.data) {
            alert("Product has been successfully deleted");
            await dispatch(deleteProductSuccess(id));
        }
        return;
    } catch (err) {
        dispatch(deleteProductFailure());
    }
};

export const updateProduct = async (id, product, dispatch) => {
    dispatch(updateProductStart());
    try {
        const result = await userRequest.put(`/products/${id}`, product);
        if (result.data) {
            alert("Product has been updated successfully");
            window.location.href = "/products";
        }
        dispatch(updateProductSuccess({ id, product }));
    } catch (err) {
        alert("Product failed to update");
        dispatch(updateProductFailure());
    }
};

export const updateUser = async (id, user) => {
    try {
        const result = await userRequest.put(`/users/adminUpdate/${id}`, user);
        if (result.data) {
            alert("User has been updated successfully");
            window.location.href = "/users";
        }
    } catch (err) {
        alert("User failed to update");
    }
};

export const addProductColor = async (id, product, dispatch) => {
    dispatch(updateProductStart());
    try {
        const result = await userRequest.put(
            `/products/addcolor/${id}`,
            product
        );
        if (result.data) {
            alert("Product has been updated successfully");
        }
    } catch (err) {
        alert("Product failed to update");
        dispatch(updateProductFailure());
    }
};

export const addProductGallery = async (id, product, dispatch) => {
    dispatch(updateProductStart());
    try {
        const result = await userRequest.put(
            `/products/addgallery/${id}`,
            product
        );
        if (result.data) {
            alert("Product has been updated successfully");
        }
    } catch (err) {
        alert("Product failed to update");
        dispatch(updateProductFailure());
    }
};

export const removeProductGallery = async (id, product, dispatch) => {
    dispatch(updateProductStart());
    try {
        const result = await userRequest.put(
            `/products/removegallery/${id}`,
            product
        );
        if (result.data) {
            alert("Product has been updated successfully");
        }
    } catch (err) {
        alert("Product failed to update");
        dispatch(updateProductFailure());
    }
};

export const deliveryOrderUpload = async (id, product, dispatch) => {
    dispatch(updateProductStart());
    try {
        const result = await userRequest.put(
            `/deliveryOrder/upload/${id}`,
            product
        );
        if (result.data) {
            alert("Delivery Order PDF has been updated successfully");
        }
    } catch (err) {
        alert("Delivery Order failed to update");
        dispatch(updateProductFailure());
    }
};

export const updateProductLabel = async (id, product, dispatch) => {
    dispatch(updateProductLabelStart());
    try {
        const result = await userRequest.put(
            `/products/productlabel/${id}`,
            product
        );
        if (result.data) {
            alert("Product has been updated successfully");
            window.location.href = "/products";
        }
    } catch (err) {
        alert("Product failed to update");
        dispatch(updateProductLabelFailure());
    }
};

export const getWarranties = async (dispatch, filters) => {
    dispatch(getWarrantyStart());
    try {
        const res = await publicRequest.get("/warranty", {
            params: { filters },
        });
        return res.data;
    } catch (err) {
        dispatch(getWarrantyFailure());
    }
};


export const getDeliveryWarranties = async (dispatch, filters) => {
    dispatch(getWarrantyStart());
    try {
        const res = await publicRequest.get("/warranty/delivery", {
            params: { filters },
        });
        await dispatch(getWarrantySuccess(res.data));
        return res.data;
    } catch (err) {
        dispatch(getWarrantyFailure());
    }
};


export const getStockCheck = async (dispatch, filters) => {
    dispatch(getWarrantyStart());
    try {
        const res = await publicRequest.get("/warranty/stocklist", {
            params: { filters },
        });
        await dispatch(getWarrantySuccess(res.data));
        return res.data;
    } catch (err) {
        dispatch(getWarrantyFailure());
    }
};

export const returnWarranty = async (inputs) => {
    try {
        const result = await userRequest.put(`/warranty/return/${inputs.id}`, {
            params: inputs.remarks,
        });
        if (result.data) {
            await createWarrantyReturn(result.data);
            alert("Warranty has been returned");
            window.location.href = "/warranties";
        }
    } catch (err) {
        alert("Warranty failed to return, please contact admin");
    }
};

export const completeWarranty = async (inputs) => {
    try {
        const result = await userRequest.put(
            `/warranty/complete/${inputs.id}`,
            { params: inputs.servicePrice }
        );
        if (result.data) {
            await createWarrantyComplete(result.data);
            alert("Warranty has been completed");
            window.location.href = "/warranties";
        }
    } catch (err) {
        alert("Warranty failed to complete, please contact admin");
    }
};

export const deleteWarranty = async (id, dispatch) => {
    dispatch(deleteWarrantyStart());
    try {
        const result = await userRequest.put(`/warranty/delete/${id}`);
        if (result.data) {
            alert("Warranty has been successfully deleted");
        }
        dispatch(deleteWarrantySuccess(id));
        return result.data;
    } catch (err) {
        dispatch(deleteWarrantyFailure());
    }
};

export const checkstock = async (stock, dispatch) => {
    dispatch(updateProductStart());
    try {
        const result = await userRequest.get(`/warranty/checklist`, {
            params: { stock },
        });

        if (result.data) {
            alert("Stock check has been updated successfully");
            window.location.href = "/checklist";
        }
    } catch (err) {
        alert("Product failed to update");
        dispatch(updateProductFailure());
    }
};


export const transferStocks = async (payload, dispatch) => {
    dispatch(updateProductStart());
    try {
        const result = await userRequest.post(`/deliveryOrder`, payload);

        if (result.data) {
            alert("Stock transfer request has been successful. Please wait for HQ to approve and process the request.");
            window.location.href = "/transfer";
        }
    } catch (err) {
        alert("Product failed to update");
        dispatch(updateProductFailure());
    }
};

export const addProduct = async (product, dispatch) => {
    dispatch(addProductStart());
    try {
        const res = await userRequest.post(`/products`, product);
        if (res.data) {
            alert("New product has been created");
            // window.location.href = "/products";
        }
        dispatch(addProductSuccess(res.data));
    } catch (err) {
        alert(
            "Failed to create new product. Please check duplicate product name."
        );
        dispatch(addProductFailure());
    }
};

export const addWarranty = async (warranty, dispatch) => {
    dispatch(addWarrantyStart());
    try {
        const res = await userRequest.post(`/warranty`, warranty);
        if (res.data) {
            alert("New warranty has been created");
            dispatch(addWarrantySuccess(res.data));
        }
    } catch (err) {
        alert("Failed to create warranty, check if serial code already exists and that all fields are filled");
        dispatch(addWarrantyFailure());
    }
};

export const getOrders = async (dispatch, filters) => {
    dispatch(getOrderStart());
    try {
        const res = await publicRequest.get("/orders", {
            params: { filters },
        });
        return res.data;
    } catch (err) {
        dispatch(getOrderFailure());
    }
};

export const getCommissions = async (dispatch, filters) => {
    dispatch(getOrderStart());
    try {
        const res = await publicRequest.get("/orders/commission", {
            params: { filters },
        });
        return res.data;
    } catch (err) {
        dispatch(getOrderFailure());
    }
};

export const getCompletedDeposit = async (dispatch, filters) => {
    dispatch(getOrderStart());
    try {
        const res = await publicRequest.get("/orders/completedeposit", {
            params: { filters },
        });
        return res.data;
    } catch (err) {
        dispatch(getOrderFailure());
    }
};

export const getHistory = async (dispatch, filters) => {
    dispatch(getOrderStart());
    try {
        const res = await publicRequest.get("/orders/history", {
            params: { filters },
        });
        return res.data;
    } catch (err) {
        dispatch(getOrderFailure());
    }
};

export const getToppenReport = async (dispatch, filters) => {
    dispatch(getMallReportStart());
    try {
        const res = await publicRequest.get("/orders/toppenReport", {
            params: { filters },
        });
        dispatch(getToppenReportSuccess(res.data));
    } catch (err) {
        dispatch(getMallReportFailure());
        alert("Please select outlets with Machine IDs");
    }
};

export const getVelocityReport = async (dispatch, filters) => {
    dispatch(getMallReportStart());
    try {
        const res = await publicRequest.get("/orders/velocityReport", {
            params: { filters },
        });
        dispatch(getVelocityReportSuccess(res.data));
    } catch (err) {
        dispatch(getMallReportFailure());
        alert("Please select outlets with Machine IDs");
    }
};

export const getSalesReport = async (dispatch, filters) => {
    dispatch(getSalesReportStart());
    try {
        const res = await publicRequest.get("/orders/salesReport", {
            params: { filters },
        });
        dispatch(getSalesReportSuccess(res.data));
        return res.data;
    } catch (err) {
        dispatch(getSalesReportFailure());
    }
};

export const getReport = async (dispatch, filters) => {
    dispatch(getReportStart());
    try {
        const res = await publicRequest.get("/orders/report", {
            params: { filters },
        });
        dispatch(getReportSuccess(res.data));
        return res.data;
    } catch (err) {
        dispatch(getReportFailure());
    }
};

export const getProductReport = async (dispatch, filters) => {
    dispatch(getProductReportStart());
    try {
        const res = await publicRequest.get("/products/productreport", {
            params: { filters },
        });

        dispatch(getProductReportSuccess(res.data));
        return res.data;
    } catch (err) {
        dispatch(getProductReportFailure());
    }
};

export const getAdminReport = async (dispatch, filters) => {
    dispatch(getProductReportStart());
    try {
        const res = await publicRequest.get("/products/adminreport", {
            params: { filters },
        });

        dispatch(getProductReportSuccess(res.data));
        return res.data;
    } catch (err) {
        dispatch(getProductReportFailure());
    }
};

export const getUsers = async (dispatch, filters) => {
    dispatch(getUserStart());
    try {
        const res = await publicRequest.get("/users", {
            params: { filters },
        });
        dispatch(getUserSuccess(res.data));
        return res.data;
    } catch (err) {
        dispatch(getUserFailure());
    }
};

export const getAdmins = async (dispatch, filters) => {
    dispatch(getUserStart());
    try {
        const res = await publicRequest.get("/users/admin", {
            params: { isAdmin: true },
        });
        dispatch(getUserSuccess(res.data));
        return res.data;
    } catch (err) {
        dispatch(getUserFailure());
    }
};

export const cancelOrder = async (id, dispatch) => {
    dispatch(cancelOrderStart());
    try {
        const result = await userRequest.put(`/orders/cancel/${id}`);
        if (result.data) {
            alert("Order has been marked as cancelled");
            window.location.href = "/orders";
        }
    } catch (err) {
        alert("Order has been failed to be marked as cancelled");
    }
};

export const voidOrder = async (id, dispatch) => {
    dispatch(voidOrderStart());
    try {
        const result = await userRequest.put(`/orders/void/${id}`);
        if (result.data) {
            alert("Order has been void");
            window.location.href = "/orders";
        }
    } catch (err) {
        alert("Order failed to void");
    }
};

export const voidCampaign = async (id, dispatch) => {
    dispatch(voidCampaignStart());
    try {
        const result = await userRequest.put(`/campaign/void/${id}`);
        if (result.data) {
            alert("Campaign has been void");
            window.location.href = "/campaigns";
        }
    } catch (err) {
        alert("Campaign failed to void");
    }
};

export const acceptCampaign = async (
    { id, watchModel, discount, color },
    dispatch
) => {
    const campaign = { id, watchModel, discount, color };
    dispatch(acceptCampaignStart());
    try {
        const result = await userRequest.put(
            `/campaign/accept/${id}`,
            campaign
        );
        if (result) {
            alert("Campaign has been accepted");
            await createCampaignInvoice(result.data);
            setTimeout(function() {
                window.location.href = "/campaigns";
            }, 2000);
        }
    } catch (err) {
        alert("Campaign is already accepted");
    }
};

export const voidDeliveryOrder = async (id, dispatch) => {
    dispatch(voidCampaignStart());
    try {
        const result = await userRequest.put(`/deliveryorder/void/${id}`);
        if (result.data) {
            alert("Delivery Order has been void");
            window.location.href = "/deliveryorders";
        }
    } catch (err) {
        alert("Campaign failed to void");
    }
};

export const acceptDeliveryOrder = async (
    { id, transporter },
    dispatch
) => {
    const campaign = { id, transporter };
    dispatch(acceptCampaignStart());
    try {
        const result = await userRequest.put(
            `/deliveryorder/accept/${id}`,
            campaign
        );

        if (result) {
            alert("Delivery Order has been accepted");
            await createDeliveryOrderForm(result.data);
            setTimeout(function() {
                window.location.href = "/deliveryorders";
            }, 2000);
        }
    } catch (err) {
        alert("Delivery Order is already accepted");
    }
};

export const addStock = async (stock, dispatch) => {
    dispatch(addStockStart());
    try {
        const res = await userRequest.post(`/stock`, stock);
        if (res.data) {
            alert("Stock has been updated");
            window.location.href = "/stocks";
        } else {
            alert("Failed to create new stock");
        }
        dispatch(addStockSuccess(res.data));
    } catch (err) {
        alert("Failed to create new stock");
        dispatch(addStockFailure());
    }
};

export const getStocks = async (dispatch, filters) => {
    dispatch(getStockStart());
    try {
        const res = await publicRequest.get("/stock", {
            params: { filters },
        });
        dispatch(getStockSuccess(res.data));
    } catch (err) {
        dispatch(getStockFailure());
    }
};

export const transferStock = async (id, payload, dispatch) => {
    dispatch(updateStockStart());
    try {
        const result = await userRequest.put(
            `/warranty/transfer/${id}`,
            payload
        );
        if (result.data) {
            alert("Stock has been updated successfully");
        }
        dispatch(updateStockSuccess({ id, payload }));
        return;
    } catch (err) {
        dispatch(updateStockFailure());
    }
};

export const getTransactions = async (dispatch, filters) => {
    dispatch(getTransactionStart());
    try {
        const res = await publicRequest.get("/transactions", {
            params: { filters },
        });

        res.data.payload.forEach((item) => {
            const str = item.productColor;
            const lastDashIndex = str.lastIndexOf("-");
            item.productColor =
                lastDashIndex !== -1 ? str.substring(lastDashIndex + 1) : str;
        });

        const payload = { ...res.data, filters };
        await dispatch(getTransactionSuccess(payload));
        return res.data;
    } catch (err) {
        dispatch(getTransactionFailure());
    }
};

export const generateSignature = async () => {
    try {
        const res = await userRequest.get(`/imagekit`);
        return res;
    } catch (err) {
        console.log("error");
    }
};

export const getAffiliates = async (dispatch, filters) => {
    dispatch(getAffiliatesStart());
    try {
        const res = await publicRequest.get("/affiliates", {
            params: { filters },
        });
        await dispatch(getAffiliatesSuccess(res.data));
        return res.data;
    } catch (err) {
        dispatch(getAffiliatesFailure());
    }
};

export const getAffiliatesReport = async (dispatch, filters) => {
    dispatch(getAffiliatesReportStart());
    try {
        const res = await publicRequest.get("/affiliates/report", {
            params: { filters },
        });
        await dispatch(getAffiliatesReportSuccess(res.data));
        return res.data;
    } catch (err) {
        dispatch(getAffiliatesReportFailure());
    }
};

export const addAffiliate = async (affiliate, dispatch) => {
    dispatch(addAffiliateStart());
    try {
        const res = await userRequest.post(`/affiliates`, affiliate);
        if (res.data) {
            alert("New affiliate has been created");
            // window.location.href = "/affiliates";
        }
        dispatch(addAffiliateSuccess(res.data));
    } catch (err) {
        alert("Failed to create new affiliate");
        dispatch(addAffiliateFailure());
    }
};

export const deleteAffiliate = async (id, dispatch) => {
    dispatch(deleteAffiliateStart());
    try {
        const result = await userRequest.put(`/affiliates/delete/${id}`);
        if (result.data) {
            alert("Promo Code has been successfully deleted");
        }
        dispatch(deleteAffiliateSuccess(id));
    } catch (err) {
        dispatch(deleteAffiliateFailure());
    }
};

export const getCampaigns = async (dispatch, filters) => {
    dispatch(getCampaignsStart());
    try {
        const rewriteAddressToString = array => array.map(item => ({
            ...item,
            address: `${item.address.lineOne}, ${item.address.city}, ${item.address.country} ${item.address.zip}`
          }));

        const res = await publicRequest.get("/campaign", {
            params: { filters },
        });

        const rewrittenArray = rewriteAddressToString(res.data.campaigns);
        dispatch(getCampaignsSuccess(res.data.campaigns));
        return {
            campaigns: rewrittenArray,
            totalPages: res.data.totalPages,
        };
    } catch (err) {
        dispatch(getCampaignsFailure());
    }
};

export const getDeliveryOrders = async (dispatch, filters) => {
    dispatch(getCampaignsStart());
    try {
        const res = await publicRequest.get("/deliveryOrder", {
            params: { filters },
        });

        dispatch(getCampaignsSuccess(res.data.deliveryOrders));
        return {
            campaigns: res.data.deliveryOrders,
            totalPages: res.data.totalPages,
        };
    } catch (err) {
        dispatch(getCampaignsFailure());
    }
};


