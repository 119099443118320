import { createSlice } from "@reduxjs/toolkit";

export const productSlice = createSlice({
    name: "warranty",
    initialState: {
        warranties: [],
        isFetching: false,
        error: false,
    },
    reducers: {
        //GET ALL
        getWarrantyStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getWarrantySuccess: (state) => {
            state.isFetching = false;
        },
        getWarrantyFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        //UPDATE
        addWarrantyStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        addWarrantySuccess: (state, action) => {
            state.isFetching = false;
        },
        addWarrantyFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        //DELETE
        deleteWarrantyStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        deleteWarrantySuccess: (state, action) => {
            state.isFetching = false;
            state.products.splice(
                state.products.findIndex((item) => item._id === action.payload),
                1
            );
        },
        deleteWarrantyFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
    },
});

export const {
    getWarrantyStart,
    getWarrantySuccess,
    getWarrantyFailure,
    addWarrantyStart,
    addWarrantySuccess,
    addWarrantyFailure,
    deleteWarrantyStart,
    deleteWarrantySuccess,
    deleteWarrantyFailure,
} = productSlice.actions;

export default productSlice.reducer;
